/* frontend/src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;


/* Add this at the bottom of your index.css */
.fade-in {
    animation: fadeIn 0.5s ease-out forwards;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; transform: scale(0.95); }
    to { opacity: 1; transform: scale(1); }
  }

 /* Custom Install Prompt Styling */
#installPrompt {
  backdrop-filter: blur(8px); /* Adds a blur effect to the background */
  z-index: 1000;
}

#installPrompt .bg-white {
  width: 90%;
  max-width: 400px;
}

#installButton {
  transition: background-color 0.3s ease;
}

#cancelButton {
  transition: color 0.3s ease;
}

#cancelButton:hover {
  color: black;
}